export default {
  data() {},

  created() {},

  methods: {
    sampleGeneratorValidator() {
      let flat = true;
      if (!['Speaking', 'Writing'].includes(this.skills[this.indexSkill].skill_type)) {
        this.messError('Không áp dụng cho kĩ năng này');
        flat = false;
      }
      if (!this.inputTitleQuestion) {
        this.messError('Hãy nhập tiêu đề câu hỏi');
        flat = false;
      }

      if (!this.idea_input) {
        this.messError('Hãy nhập idea');
        flat = false;
      }

      let partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;

      if (!partConfig){
        this.messError('Chưa có cấu hình part cho section này');
        flat = false;
      }
      if (partConfig.module === 1 ) {
        if (partConfig.offset === 2){
          if (!this.element_description) {
            this.messError('Hãy nhập mô tả ảnh');
            flat = false;
          }
        }
        if (partConfig.offset === 3 || partConfig.offset === 4) {
          if (!this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_title) {
            this.messError('Hãy nhập tiêu đề của exercise tương ứng');
            flat = false;
          }
        }
        if (partConfig.offset === 4) {
          if (!this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].element_description) {
            this.messError('Hãy nhập mô tả ảnh của exercise tương ứng');
            flat = false;
          }
        }
      }
      if (partConfig.module === 2) {
        if(this.optionDirection.filter((e => e.content)) <=0 ) {
          this.messError('Hãy nhập ít nhất 1 direction option');
          flat = false;
        }

      }
      return flat;
    },

    messError(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },

    getParameterSampleToGenerator() {
      let partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
      let data = {
        part_id: partConfig.id,
        question_text: this.inputTitleQuestion,
        photo_description: this.element_description,
        narrator: this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_title,
        information:this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].element_description,
        suggestions: this.idea_input
      }

      if (partConfig.module === 2) {
        data.question_text = this.optionDirection.map(e => e.content).join('\n');
        data.information = this.inputTitleQuestion;
      }

      return data;
    }
  },
};
