<template>
  <div style="width: 100%">
    <v-col cols="12" class="mt-0 pb-0">
      <p class="red--text m-0 mb-4">
        * Hãy nhập các đáp án và chọn đáp án đúng
      </p>
      <p class="red--text m-0 mb-4">
        * Hãy di chuyển các mục (A, B, C, ...) để di chuyển thứ tự theo ý muốn
      </p>
      <draggable :list="optionSingleChoiceEditor" @change="resetCorrect">
        <template v-for="(option, i) in optionSingleChoiceEditor">
          <div style="display: flex" :key="i" class="mb-4">
            <div :style="[hasConfigPhonetic ? {'width': '65%'} : {'width': '100%'}]" class="d-flex">
              <p class="text-h5 mr-2 mt-2" style="color: #6495ed">
                {{ i | getAlphabetCharacterFromIndex }}.
              </p>
              <v-checkbox
                  class="mt-2"
                  hide-details
                  v-model="option.checked"
                  @click="chooseCorrect(i)"
              ></v-checkbox>
              <div style="width: 100%; display: inline-block">
<!--                <label style="font-size: 16px">{{ "Option " + (i + 1) }}</label>-->
                <EditorBalloon v-model="option.value" :ref="'single-choice-editor-'+i"></EditorBalloon>
              </div>
              <v-tooltip bottom color="#181c32f0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-if="hasConfigPhonetic"
                      v-bind="attrs" v-on="on"
                      outlined color="#039BE5"
                      class="mt-2 ml-1"
                      @click="getPinyinFromChineseTextSelectedToRefInputEditorBalloon('single-choice-editor-'+i)"
                  >
                    Thêm phiên âm
                  </v-btn>
                </template>
                <div style="max-width: 500px; color: white">
                  Thầy cô vui lòng bổ sung dấu cách giữa các từ có nghĩa trước khi thêm phiên âm để đảm bảo phiên âm hiển thị đúng vị trí các từ. Sau khi thêm phiên âm, thầy cô có thể điều chỉnh pinyin bằng cách edit nội dung pinyin giữa các thẻ &lt;/rt&gt;
                </div>
              </v-tooltip>

              <v-btn
                  outlined color="grey"
                  class="ml-1 mt-2"
                  @click="deleteOption(i)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <div style="width: 35%; border: 1px solid grey" v-if="hasConfigPhonetic" class="rounded ml-1">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
              <div
                  style="height: 40px; overflow: auto"
                  class="rounded px-1"
                  v-html="decodeHTMLForChineseCharacter(option.value)">
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </v-col>
    <v-col cols="3" class="mt-3 mb-4">
      <v-btn large @click="addOption">
        <v-icon>mdi-plus</v-icon> Add Option
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import EditorBalloon from "@/components/ckeditor5/EditorBalloon";
import draggable from "vuedraggable";
import phoneticSupport from "../Support/Question/phoneticSupport";
export default {
  name: "SingleChoiceEditor",
  components: {
    EditorBalloon,
    draggable,
  },
  mixins: [
      phoneticSupport
  ],
  props: {
    option: {
      type: Array,
      default: () => [],
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      // optionSingleChoiceEditor: [{ value:'', checked: false}, { value:'', checked: false}],
    };
  },
  computed: {
    optionSingleChoiceEditor: {
      get() {
        return this.option;
      },
      set(value) {
        this.$emit("setOptionSingleChoiceEditor", value);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  methods: {
    chooseCorrect(index) {
      this.optionSingleChoiceEditor = this.optionSingleChoiceEditor.map(
        function (item, key) {
          return {
            value: item["value"],
            checked: key === index,
          };
        }
      );
    },
    addOption() {
      let data = { value: "", checked: false };
      this.optionSingleChoiceEditor.push(data);
    },
    deleteOption(index) {
      if (this.optionSingleChoiceEditor.length <= 1) {
        this.$toasted.error("Phải có tối thiểu 1 option !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.optionSingleChoiceEditor.splice(index, 1);
    },
    resetCorrect() {
      console.log("change");
    },
    async getPinyinFromChineseTextSelectedToRefInputEditorBalloon(inputRef) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refTitleQuestion = this.$refs[inputRef][0].$refs.editorBalloonCustom;
      let editor = refTitleQuestion.$_instance;

      let textSelected = this.getTextSelectedByEditor(editor);
      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      let dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      editor.model.change( writer => {
        const range = editor.model.document.selection.getFirstRange();
        editor.model.insertContent(writer.createText(" "+dataPinyin+" ") , range )
      } );

      editor.editing.view.focus();
    },
  },
};
</script>

<style scoped></style>
