
export default {
  data() {
    return {

    }
  },
  computed: {},
  methods: {
    setPrepLabel() {
      let vm = this;
      let labels = this.$store.state.constantSupportForUnitStore.labels;
      if (labels.length === 0) return [];

      labels = JSON.parse(JSON.stringify(labels));
      let skill_id = this.skills[this.indexSkill]?.id;

      labels = labels.filter((label) => {
        return label.skill_id === skill_id && label.product_category_id === vm.product_category_id;
      });
      return labels;
    },
  },

}