<template>
  <div style="width: 100%">
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      v-model="inputVal"
      :disabled="disabled"
      id="editorBalloon"
      v-bind:style="{ minHeight: minHeight + 'px !important' }"
      ref="editorBalloonCustom"
    ></ckeditor>
  </div>
</template>

<script>
import EditorBalloon from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";

export default {
  name: "EditorBalloon",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "50",
    },
  },
  data() {
    return {
      editor: EditorBalloon,
      editorConfig: {
        placeholder: "",
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          HeadingPlugin,
          BoldPlugin,
          ItalicPlugin,
          Underline,
          FontPlugin,
          AlignmentPlugin,
          Clipboard,
          PasteFromOffice,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "undo",
            "redo",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "|",
            "alignment:left",
            "alignment:right",
            "alignment:center",
            "alignment:justify",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.editorConfig.placeholder = this.placeholder;
  },
};
</script>

<style scoped>
#editorBalloon {
  border: 1px solid #c4c4c4;
  /*min-height: 50px !important;*/
  max-width: 100% !important;
}
</style>
