import {mapState} from "vuex";

export default {
  data() {
    return {
      product_category_const: {
        IELTS: 1,
        THPTQG: 3,
        TOEIC: 4,
        HSK: 5,
        PREPTALK_ENGLISH_ENGLISH: 6,
      }
    }
  },
  computed: {
    ...mapState({
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
      product_categories: (state) => state.contentTestBuilderStore.product_categories,
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
  },
  methods: {
    getObjPCategoryById(product_category_id = null) {
      if (!product_category_id) return null;
      let dataFilter = this.product_categories.filter((item) => {
        return item.id == product_category_id;
      });
      dataFilter = JSON.parse(JSON.stringify(dataFilter));
      return dataFilter.length > 0 ? dataFilter[0] : null;
    }
  },

}