import constantTestBuilder from "../../../mixins/Support/constantTestBuilder";
export default {
  mixins: [
    constantTestBuilder,
  ],
  data() {
    return {
      module_section_part: {
        VSR: 1,
        VWR: 2,
        TEST_PRACTICE: 3
      },
      offset_section_part: {
        PART_1: 1,
        PART_2: 2,
        PART_3: 3,
        PART_4: 4,
        PART_5: 5,
      }
    }
  },
  computed: {

  },
  methods: {
    checkCanUseGetDesImage() {
      let flat = false;
      if ([
        this.product_category_constant.TOEIC
      ].includes(this.product_category_id)) {
        if (
          this.skillCurrent === 'Speaking' &&
          this.skills[this.indexSkill].vsr === 1
        ) {
          flat = true;
        }
      }

      if ([
        this.product_category_constant.IELTS,
        this.product_category_constant.TOEIC,
      ].includes(this.product_category_id)) {
        if (this.skillCurrent ==='Writing' && this.skills[this.indexSkill].vwr === 1) {
          if (this.product_category_id === this.product_category_const.TOEIC) {
            if (this.skills[this.indexSkill]?.sections[this.indexSection].partConfig) {
              let partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
              if (
                partConfig &&
                partConfig.module === this.module_section_part.VWR
                &&
                [
                  this.offset_section_part.PART_1,
                  this.offset_section_part.PART_2,
                ].includes(partConfig.offset)) {
                flat = true
              }
            }
          }
        }
      }

      return flat;
    },
    checkCanInputDirection() {
      if ([
        this.product_category_constant.TOEIC
      ].includes(this.product_category_id)) {
        if (
          this.skills[this.indexSkill]?.skill_type === 'Writing' &&
          this.skills[this.indexSkill]?.vwr === 1 &&
          this.skills[this.indexSkill]?.sections[this.indexSection]?.partConfig
        ) {
          let configPart = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
          if (
            configPart.module === this.module_section_part.VWR &&
            [this.offset_section_part.PART_1, this.offset_section_part.PART_2,].includes(configPart.offset)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    checkCanUserGetIdeaGeneratorFromOpenAi() {
      if ([
        this.product_category_constant.TOEIC,
      ].includes(this.product_category_id)) {
        if (this.skillCurrent ==='Speaking' && this.skills[this.indexSkill].vsr === 1) return true;
      }

      if ([
        this.product_category_constant.IELTS,
        this.product_category_constant.TOEIC
      ].includes(this.product_category_id)) {
        if (this.skillCurrent ==='Writing' && this.skills[this.indexSkill].vwr === 1) {
        if (this.skills[this.indexSkill]?.sections[this.indexSection].partConfig) {
          let partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
          if (
            partConfig.module === this.module_section_part.VWR
            &&
            [
              this.offset_section_part.PART_2,
            ].includes(partConfig.offset)) {
            return true;
          }
        }
      }
      }
      return false;
    }
  },
  filters: {}
}